import React from 'react';

function NoticeBar() {
  return (
    <div className="notice-bar">
      <p><a href="/f-s-digital-is-now-differnt/">Read News&nbsp;:&nbsp;&nbsp;F&S Digital is Now Differnt Systems</a></p>
    </div>
  );
}

export default NoticeBar;